<!--
 * @Author: 刘格优
 * @Date: 2020-06-19 18:54:56
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-06-29 17:38:23
-->
<!--  -->
<template>
  <div class="alldivbox">
    <div class="top">
      <img src="@/assets/xxb/wd6.png"
           alt="" />
      <div class="side">
        <p>{{question }}</p>
      </div>
    </div>
    <p class="desc">{{questiondesc}}</p>

    <div class="middle"></div>
    <div>
      <div class="left">
        <span class="line"></span>
        <span class="words">全部回答（{{ answernum }}）</span>
      </div>

      <div class="allbox">
        <div v-for="(item, index) in wdata.Questiondata"
             :key="index"
             class="eveques">

          <p class="phone"
             v-if="item.empno != 'ADMIN'">
            <img :src="item.headimg"
                 alt=""
                 class="headimg">
            <span>{{ item.phone }}</span>
          </p>
          <!-- <img v-else
               src="@/assets/xxb/gf.png"
               alt=""
               class="admin adminmargin"> -->
          <div v-else
               class="hltx adminmargin">
            <img src="@/assets/xxb/gft.png"
                 alt=""
                 class="admin">
            <span> 汇立天下</span>

            <img src="@/assets/xxb/gfimg.png"
                 alt=""
                 class="right">
          </div>
          <p class="answer topanswer">{{ item.answer }}</p>
          <div class="plbottom topplbottom">
            <span class="time">{{ item.showtime }}</span>
            <span class="round"></span>

            <span class="tohf"
                  @click="tohf(item, '1', '')">回复TA</span>

            <span class="delete"
                  @click="deletepl(item)"
                  v-if="user.empno ==item.empno">删除</span>
          </div>
          <div v-for="(citem, cindex) in item.answerdata"
               :key="cindex"
               class="hflast">
            <div class="phone">
              <img :src="citem.headimg"
                   alt=""
                   class="cheadimg"
                   v-if="citem.replyempno !='ADMIN'">
              <span v-if="citem.replyempno !='ADMIN'">{{ citem.replyphone }} </span>
              <!-- <img v-else
                   src="@/assets/xxb/gf.png"
                   alt=""
                   class="admin"> -->
              <div v-else
                   class="hltx small">
                <img src="@/assets/xxb/gft.png"
                     alt=""
                     class="admin">
                <span> 汇立天下</span>

                <img src="@/assets/xxb/gfimg.png"
                     alt=""
                     class="right">
              </div>
              <div class="triangle_border_right"></div>
              <!-- <img :src="citem.upheadimg"
                   alt=""
                   class="headimg"> -->
              <span class="cphone">{{ citem.upphone }}</span>
            </div>
            <p class="answer">{{ citem.reply }}</p>
            <div class="plbottom">
              <span class="time">{{ citem.showtime }}</span>
              <span class="round"></span>

              <span class="tohf"
                    @click="tohf(citem, '2', item)">回复TA</span>

              <span class="delete"
                    @click="deletehf(citem, item.answercode)"
                    v-if="user.empno ==citem.replyempno ">删除</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="botinput">
      <textarea type="text"
                class="botinput"
                placeholder="请输入评论"
                v-model="pldata"
                rows="3" />
      <span class="fbword"
            @click="topl()">发布</span>
    </div>
    <md-popup v-model="isPopupShow"
              position="bottom">
      <md-popup-title-bar title="回复"
                          describe=""
                          ok-text="发送"
                          cancel-text="关闭"
                          large-radius
                          @confirm="sendwt()"
                          @cancel="hidePopUp()"></md-popup-title-bar>
      <div class="md-example-popup md-example-popup-bottom">
        <md-field>
          <!-- <md-input-item title="回复："
                         :placeholder="`回复${phone}`"
                         class="require"
                         :solid="false"
                         name="reply"
                         v-validate="'required'"
                         :error="errors.first('reply')"
                         v-model="formdata.reply"
                         align="left"></md-input-item> -->
          <md-textarea-item title="回复："
                            v-model="formdata.reply"
                            :placeholder="`回复${phone}`"
                            :max-height="150"
                            name="reply"
                            v-validate="'required'"
                            :error="errors.first('reply')" />
        </md-field>
      </div>
    </md-popup>
  </div>
</template>

<script>
import { getQuestionmsg, addReply, addAnswer, delAnswer, delReply } from "@/api/xxb/index";
import { getStorage } from "@/lib/util";
import { Toast } from 'mand-mobile'
export default {
  data () {
    return {
      code: "",
      question: "",
      questiondesc: "",
      answernum: "",
      wdata: {},
      user: {},
      isPopupShow: false,
      formdata: {
        reply: "",
        isofficial: "1"
      },
      type: "",
      ownitem: "",
      upitem: "",
      phone: "",
      pldata: ""
    };
  },
  created () {
    this.user = getStorage("u_s", {});
    this.code = this.$route.query.questioncode;
    this.getdetail();
  },
  mounted () {
    $(document).on('focusout', function () {
      setTimeout(() => {
        // 软键盘收起的事件处理
        window.scroll(0, 0)
      }, 300)
    })
  },

  methods: {
    getdetail () {
      getQuestionmsg({ questioncode: this.code }).then(res => {
        this.wdata = res.data.data;
        this.question = this.wdata.Question.question;
        document.title = this.wdata.Question.question
        this.answernum = this.wdata.Question.answernum;
        this.questiondesc = this.wdata.Question.questiondesc;
      });
    },
    tohf (item, type, upitem) {
      this.$validator.reset();
      this.isPopupShow = true;
      this.hfshow = true;
      this.type = type;
      this.ownitem = item;
      this.upitem = upitem;
      this.formdata = {
        isofficial: "1",
        reply: ""
      };
      this.ispl = false;
      this.phone =
        this.type == "1" ? this.ownitem.phone : this.ownitem.replyphone;
    },
    topl () {
      if (this.pldata == '') {
        Toast.failed('请输入评论')
        return
      }
      let data = {
        flag: "add",
        answer: this.pldata,
        isofficial: '1',
        questioncode: this.wdata.questioncode
      }
      addAnswer(data).then((res) => {
        this.pldata = ''
        this.getdetail()
      })
    },
    deletepl (item) {
      let data = {
        questioncode: this.wdata.questioncode,
        answercode: item.answercode
      }
      delAnswer(data).then((res) => {
        this.getdetail()
      })
    },
    deletehf (item, answercode) {
      let data = {
        questioncode: this.wdata.questioncode,
        answercode: answercode,
        replycode: item.replycode
      }
      delReply(data).then((res) => {
        this.getdetail()
      })
    },
    hidePopUp () {
      this.isPopupShow = false;
    },
    sendwt () {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          let data = {
            flag: "add",
            answercode:
              this.type == "1"
                ? this.ownitem.answercode
                : this.upitem.answercode,
            upempno:
              this.type == "1" ? this.ownitem.empno : this.ownitem.replyempno,
            upphone:
              this.type == "1" ? this.ownitem.phone : this.ownitem.replyphone,
            reply: this.formdata.reply,
            isofficial: this.formdata.isofficial,
            questioncode: this.wdata.questioncode
          };
          if (data.upphone.search("我") != -1) {
            data.upphone = data.upphone.substring(0, data.upphone.length - 3)
          }
          addReply(data).then(res => {
            this.isPopupShow = false;
            this.getdetail();
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="stylus">
.alldivbox {
  height: 89%;
  overflow-y: auto;
  background-color: #ffffff;
}

.admin {
  width: 3.5rem;
}

.adminmargin {
  margin: 0.2rem 0;
}

.top {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0;

  img {
    width: 0.54rem;
    height: auto;
  }

  .side {
    padding-left: 0.2rem;

    p {
      color: #383838;
      font-size: 0.43rem;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 0.8rem;
    }
  }
}

.desc {
  color: #8d9095;
  font-size: 0.33rem;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0.6rem;
  padding-left: 1.3rem;
  padding-bottom: 0.4rem;
}

.middle {
  height: 0.22rem;
  width: 100%;
  background-color: #F5F5F5;
}

.allbox {
  padding: 0.4rem 0.5rem;
}

.qustion {
  border-bottom: 1px solid #dadada;
  padding: 5px;

  .left {
    width: 80%;
    display: inline-block;
  }

  .right {
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
}

.one {
  font-size: 16px;
  font-weight: bold;
}

.two {
  font-size: 14px;
}

.eveques {
  padding: 5px;

  .headimg {
    width: 0.7rem;
    border-radius: 0.1rem;
  }
}

.cheadimg {
  width: 0.53rem;
  border-radius: 0.1rem;
}

.cphone {
  padding-left: 0 !important;
}

.phone {
  padding: 0.1rem 0;
  display: flex;
  align-items: center;

  span {
    font-size: 0.4rem;
    font-family: PingFang SC;
    color: #4ea6e6;
    padding-left: 0.2rem;
  }
}

.answer {
  color: #393939;
  font-size: 0.39rem;
  font-family: PingFang SC;
  font-weight: 500;
  padding-left: 0.8rem;
}

.topanswer {
  padding-left: 1rem;
}

.plbottom {
  font-size: 13px;
  padding-top: 0.15rem;
  padding-bottom: 0.3rem;
  padding-left: 0.8rem;
  border-bottom: 1px solid #f1f1f1;
}

.topplbottom {
  padding-left: 1rem;
}

.hflast {
  padding: 15px 15px 15px 60px;
}

.time {
  color: #ABABAB;
  // font-size: 0.35rem;
  font-size: 0.31rem;
  font-family: PingFang SC;
}

.tohf {
  color: #4ea6e6;
  padding: 0 10px;
  // font-size: 0.35rem;
  cursor: pointer;
  font-size: 0.31rem;
  font-family: PingFang SC;
  font-weight: 500;
}

.fbbtn {
  padding-left: 12px;
  color: #4ea6e6;
  font-size: 20px;
  height: 34px;
  line-height: 34px;
  display: inline-block;
  cursor: pointer;
}

.delete {
  color: red;
  cursor: pointer;
  font-size: 0.35rem;
  padding-left: 0.5rem;
}

.left {
  padding: 0.5rem 0.5rem 0;
  display: flex;
  align-items: center;

  .line {
    display: inline-block;
    width: 0.1rem;
    height: 0.4rem;
    background-color: #FB6821;
  }

  .words {
    display: inline-block;
    padding-left: 0.2rem;
    font-size: 0.43rem;
    font-family: PingFang SC;
  }
}

.round {
  display: inline-block;
  width: 0.05rem;
  height: 0.05rem;
  background-color: #ababab;
  border-radius: 50%;
  margin-left: 0.1rem;
  position: relative;
  bottom: 0.08rem;
}

.triangle_border_right {
  width: 0;
  height: 0;
  border-width: 0.1rem 0 0.1rem 0.1rem;
  border-style: solid;
  border-color: transparent transparent transparent #ababab;
  display: inline-block;
  margin: 0 0.1rem;
}

.botinput {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 2%;

  textarea {
    background-color: #f5f5f5;
    width: 80%;
    padding-left: 3%;
    padding-top: 0.3rem;
    font-size: 0.38rem;
  }

  .fbword {
    color: #4EA6E6;
    font-size: 0.44rem;
    font-family: PingFang SC;
    font-weight: 500;
    position: absolute;
    bottom: 0.7rem;
    right: 5%;
  }
}

/deep/ .md-textarea-item__textarea {
  font-size: 0.38rem !important;
}

.small {
  .admin {
    width: 0.53rem !important;
  }
}

.hltx {
  display: flex;
  align-items: center;

  .admin {
    width: 0.7rem;
    border-radius: 0.1rem;
  }

  .right {
    width: 0.8rem;
    padding-right: 0.2rem;
  }

  span {
    font-size: 0.44rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #4ea6e6;
    padding-left: 0.2rem;
  }
}
</style>
